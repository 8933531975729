import React, { useState, useEffect } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Loader from "components/Loader";
const Users = () => {
    const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const headers = [ 
    { key: "createdAt", label: "Created At" },
    { key: "emailVerified", label: "Email Verified" },
    { key: "init", label: "Intake" },
    { key: "terms", label: "Terms" },
    { key: "email", label: "Email" },
    { key: "name", label: "Name" },
    { key: "chatCount", label: "Chats" },
    { key: "tripCount", label: "Trips" },
    { key: "uid", label: "UID" }, 
    { key: "authProvider", label: "Auth Provider" },
    // { key: "displayName", label: "Display Name" },
  ];

  useEffect(() => {
    const fetchUsers = async () => {
      const functions = getFunctions();
      const getAllUsers = httpsCallable(functions, "getAllUsers");
      const db = getFirestore();

      try {
        setLoading(true);
        setError("");

        const usersResult = await getAllUsers();
        const rawUsers = usersResult.data.users;

        const sortedUsers = rawUsers.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        const chatsSnapshot = await getDocs(collection(db, "chats"));
        const tripsSnapshot = await getDocs(collection(db, "trips"));
        const usersSnapshot = await getDocs(collection(db, "users"));

        const chatCounts = {};
        const tripCounts = {};
        const inits = {}
        const terms = {}
        const names = {}

        chatsSnapshot.docs.forEach((doc) => {
          const participants = doc.data().participants || {};
          Object.keys(participants).forEach((uid) => {
            chatCounts[uid] = (chatCounts[uid] || 0) + 1;
          });
        });

        tripsSnapshot.docs.forEach((doc) => {
          const uid = doc.data().uid;
          tripCounts[uid] = (tripCounts[uid] || 0) + 1;
        });

        usersSnapshot.docs.forEach((doc) => {            
            inits[doc.id] = doc.data().init || false;
            terms[doc.id] = doc.data().termsAccepted || false;
            names[doc.id] = doc.data().name || null;
        });

        const processedUsers = sortedUsers.map((user) => ({
            ...user,
            chatCount: chatCounts[user.uid] || 0,
            tripCount: tripCounts[user.uid] || 0,
            createdAt: new Date(user.createdAt).toLocaleString(),
            init: inits[user.uid],
            terms: terms[user.uid],
            name: names[user.uid]
        }));
        
        setUsers(processedUsers);
        setFilteredUsers(processedUsers);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${String(date.getDate()).padStart(2, "0")}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${date.getFullYear()}`;
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    console.log(term);
    
    const filtered = users.filter(
      (user) =>
        user.email?.toLowerCase().includes(term) || user.uid.includes(term)
    );
    setFilteredUsers(filtered);
    setSearchTerm(term);
  };

  const handleClick = (user, header) => {
        const copyToClipboard = (text)=>{
            navigator.clipboard.writeText(text)
            .then(() => {
                alert(text);
            })
            .catch(err => {
                alert('Failed to copy text: ', err);
            });
        }
        if(header.key=="name") navigate(`/user/${user.uid}`);
        else if(header.key=="uid") copyToClipboard(user.uid);
        else if(header.key=="email") copyToClipboard(user.email);
  };

  if (loading) return <Loader/>
  if (error) return <div>{error}</div>;

  return (
    <div className="h-[calc(100vh-7rem)] flex flex-col max-w-full bg-base-100 m-5">
      <h1 className="text-2xl font-bold mb-4">{users.length} Users</h1>

      <div className="mb-6">
        <input
          type="text"
          placeholder="Search by email or UID"
          value={searchTerm}
          onChange={handleSearch}
          className="w-full p-2 border border-gray-300 rounded-lg"
        />
      </div>

      <div className="overflow-auto flex-1">
        <table className="min-w-full table-auto border-collapse border border-gray-300">
            <thead>
            <tr>
                {headers.map((header) => (
                <th
                    key={header.key}
                    className="border border-gray-300 px-4 py-2 capitalize whitespace-nowrap"
                >
                    {header.label}
                </th>
                ))}
            </tr>
            </thead>
            <tbody>
            {filteredUsers.map((user, index) => (
                <tr
                key={index}
                className="cursor-pointer"
                >
                {headers.map((header) => {
                    const value = user[header.key];
                    let className = "border border-gray-300 px-4 py-2 text-center";

                    if (header.key === "authProvider") {
                        className += value === "google.com" ? " bg-blue-900" : " bg-yellow-900";
                    } else if (header.key === "name" || header.key === "tripCount" || header.key === "chatCount" || header.key === "displayName" || header.key === "init" || header.key === "terms" || header.key === "emailVerified") {
                        className += value ? " bg-green-900" : " bg-red-900";
                    }
                    
                    return (
                        <td key={header.key} className={`${className} whitespace-nowrap`} onClick={() => handleClick(user, header)}>
                            {value===null || value===undefined?"N/A":value.toString()}
                        </td>
                    );
                })}
                </tr>
            ))}
            </tbody>
        </table>
      </div>
    </div>
  );
};

export default Users;
