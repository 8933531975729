import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import { Link } from "react-router-dom";
import Loader from "components/Loader";

const User = () => {
  const { uid } = useParams(); // Extract UID from the URL
  const [userData, setUserData] = useState(null); // User data fetched from Cloud Function
  const [userTrips, setUserTrips] = useState([]);
  const [userChats, setUserChats] = useState([]);
  const [chatCounts, setChatCounts] = useState({});
  const [tripCounts, setTripCounts] = useState({});
  const [inits, setInits] = useState({});
  const [terms, setTerms] = useState({});
  const [names, setNames] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      const functions = getFunctions();
      const getAllUsers = httpsCallable(functions, "getAllUsers");

      try {
        setLoading(true);
        setError("");

        // Fetch user data from Cloud Function
        const response = await getAllUsers({ uid });
        setUserData(response.data.user);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch user data. Please try again later.");
      }
    };

    const fetchTripsAndChats = async () => {
      const db = getFirestore();

      try {
        const chatCountsTemp = {};
        const tripCountsTemp = {};
        const initsTemp = {};
        const termsTemp = {};
        const namesTemp = {};

        // Fetch chats involving this user
        const chatsQuery = query(collection(db, "chats"), where(`participants.${uid}`, "==", true));
        const chatsSnapshot = await getDocs(chatsQuery);
        const chatsData = chatsSnapshot.docs.map((doc) => {
          const participants = doc.data().participants || {};
          Object.keys(participants).forEach((uid) => {
            chatCountsTemp[uid] = (chatCountsTemp[uid] || 0) + 1;
          });
          return { id: doc.id, ...doc.data() };
        });
        setUserChats(chatsData);
        setChatCounts(chatCountsTemp);

        // Fetch trips posted by this user
        const tripsQuery = query(collection(db, "trips"), where("uid", "==", uid));
        const tripsSnapshot = await getDocs(tripsQuery);
        const tripsData = tripsSnapshot.docs.map((doc) => {
          const tripUid = doc.data().uid;
          tripCountsTemp[tripUid] = (tripCountsTemp[tripUid] || 0) + 1;
          return { id: doc.id, ...doc.data() };
        });
        setUserTrips(tripsData);
        setTripCounts(tripCountsTemp);

        // Fetch other user-related data
        const usersQuery = query(collection(db, "users"), where("__name__", "==", uid));
        const usersSnapshot = await getDocs(usersQuery);
        usersSnapshot.docs.forEach((doc) => {
          initsTemp[doc.id] = doc.data().init || false;
          termsTemp[doc.id] = doc.data().termsAccepted || false;
          namesTemp[doc.id] = doc.data().name || null;
        });
        setInits(initsTemp);
        setTerms(termsTemp);
        setNames(namesTemp);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch trips or chats. Please try again later.");
      }
    };

    // Fetch user data and related trips/chats
    if (uid) {
      fetchUserData().then(fetchTripsAndChats).finally(() => setLoading(false));
    }
  }, [uid]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div className="p-6 text-center text-red-500">{error}</div>;
  }

  if (!userData) {
    return <div className="p-6 text-center text-red-500">No user data found.</div>;
  }

  return (
    <div className="max-w-6xl mx-auto shadow-md rounded-lg p-6">
      <h1 className="text-2xl font-bold mb-6">User Details</h1>

      {/* User Information */}
      <div className="mb-6">
        <h2 className="text-xl font-semibold">Profile</h2>
        <div>
          <p className="cursor-pointer" onClick={() => window.open(`https://app.ride-share.app/profile/${userData.uid}`, "_blank")}>
            <strong>UID:</strong> {userData.uid}
          </p>
          <p><strong>Name:</strong> {names[uid] || userData.displayName || "N/A"}</p>
          <p><strong>Email:</strong> {userData.email || "N/A"}</p>
          <p>
            <strong>Created At:</strong>{" "}
            {userData.createdAt ? new Date(userData.createdAt).toLocaleString() : "N/A"}
          </p>
          <p><strong>Init:</strong> {inits[uid] ? "Yes" : "No"}</p>
          <p><strong>Terms Accepted:</strong> {terms[uid] ? "Yes" : "No"}</p>
        </div>
      </div>

      {/* User Trips */}
      <div className="mb-6">
        <h2 className="text-xl font-semibold">Trips: {userTrips.length}</h2>
        {userTrips.length > 0 ? (
          <ul className="list-disc pl-6">
            {userTrips.map((trip) => (
              <li key={trip.id}>
                <span className="cursor-pointer" onClick={() => window.open(`https://app.ride-share.app/trip-details/${trip.id}`, "_blank")}>
                  <strong>Trip ID:</strong> {trip.id}
                </span> <br />
                <strong>Start:</strong> {trip.startLocationName || "N/A"} <br />
                <strong>End:</strong> {trip.endLocationName || "N/A"} <br />
                <strong>Created At:</strong>{" "}
                {trip.createdAt ? new Date(trip.createdAt).toLocaleString() : "N/A"}
              </li>
            ))}
          </ul>
        ) : (
          <p>No trips found for this user.</p>
        )}
      </div>

      {/* User Chats */}
      <div>
        <h2 className="text-xl font-semibold">Chats: {userChats.length}</h2>
        {userChats.length > 0 ? (
          <ul className="list-disc pl-6">
            {userChats.map((chat) => (
              <li key={chat.id}>
                <strong>Chat ID:</strong> <Link to={`/chats/${chat.id}`} className="text-blue-500 underline">{chat.id}</Link> <br />
              </li>
            ))}
          </ul>
        ) : (
          <p>No chats found for this user.</p>
        )}
      </div>

      {/* User Stats */}
      <div className="mt-6">
        <h2 className="text-xl font-semibold">User Stats</h2>
        <p><strong>Total Chats:</strong> {chatCounts[uid] || 0}</p>
        <p><strong>Total Trips:</strong> {tripCounts[uid] || 0}</p>
      </div>
    </div>
  );
};

export default User;
