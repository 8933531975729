import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

// MenuItem Component
const MenuItem = ({ path, name, closeMenu }) => {
  return (
    <li>
      <NavLink
        to={path}
        className={({ isActive }) =>
          "text-2xl "+(isActive
            ? "text-yellow-300 font-semibold"
            : "hover:text-gray-200")
        }
        onClick={closeMenu}
      >
        {name}
      </NavLink>
    </li>
  );
};

// Header Component
const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleTitleClick = () => {
    navigate("/");
    closeMenu();
  };

  const menuItems = [
    { name: "Home", path: "/" },
    { name: "Users", path: "/users" },
    { name: "Trips", path: "/trips" },
    { name: "Chats", path: "/chats" },
  ];

  return (
    <header className="bg-blue-500 text-white p-4 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        {/* Title */}
        <h1
          className="text-2xl font-bold cursor-pointer"
          onClick={handleTitleClick}
        >
          Ride Share Admin
        </h1>

        {/* Hamburger Menu Button */}
        <button
          className="lg:hidden block text-white focus:outline-none"
          onClick={toggleMenu}
          aria-label="Toggle navigation menu"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d={
                menuOpen
                  ? "M6 18L18 6M6 6l12 12"
                  : "M4 6h16M4 12h16M4 18h16"
              }
            />
          </svg>
        </button>

        {/* Navigation Links */}
        <nav
          className={`lg:flex lg:items-center lg:space-x-4 absolute lg:relative top-16 left-0 w-full lg:w-auto bg-blue-500 lg:bg-transparent lg:top-auto lg:left-auto p-4 lg:p-0 transition-all duration-300 ${
            menuOpen ? "block" : "hidden"
          }`}
        >
          <ul className="flex flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
            {menuItems.map((item) => (
              <MenuItem
                key={item.path}
                path={item.path}
                name={item.name}
                closeMenu={closeMenu}
              />
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
