import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs, query, orderBy } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Loader from "components/Loader";

const Trips = () => {
    const navigate = useNavigate()
  const [trips, setTrips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // Define table headers
  const headers = [
    { key: "id", label: "trip ID" },
    { key: "timeZoneId", label: "Time Zone" },
    { key: "createdAt", label: "Created At" },
    { key: "startLocationName", label: "Start Location" },
    { key: "endLocationName", label: "End Location" },
    { key: "uid", label: "User" },
  ];

  useEffect(() => {
    const fetchTrips = async () => {
      const db = getFirestore();

      try {
        setLoading(true);
        setError("");

        // Query trips collection sorted by creation date (timestamp)
        const tripsQuery = query(collection(db, "trips"), orderBy("createdAt", "desc"));
        const tripsSnapshot = await getDocs(tripsQuery);

        // Map the trips data into an array
        const tripsData = tripsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setTrips(tripsData);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch trips. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchTrips();
  }, []);

  const handleClick = (trip, header) => {
        const copyToClipboard = (text)=>{
            navigator.clipboard.writeText(text)
            .then(() => {
                alert(text);
            })
            .catch(err => {
                alert('Failed to copy text: ', err);
            });
        }
        if(header.key=="id") window.open("https://app.ride-share.app/trip-details/"+trip.id,"_blank");
        else if(header.key=="uid") navigate(`/user/${trip.uid}`)
    };

  if (loading) {
    return <Loader/>;
  }

  if (error) {
    return <div className="p-6 text-center text-red-500">{error}</div>;
  }

  return (
    <div className="h-[calc(100vh-7rem)] flex flex-col max-w-full mx-auto shadow-md rounded-lg p-6">
      <h1 className="text-2xl font-bold mb-6">All Trips - {trips.length}</h1>
      <div className="overflow-auto flex-1">
        <table className="min-w-full table-auto border-collapse border border-gray-300">
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header.key} className="border border-gray-300 px-4 py-2">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {trips.map((trip) => (
              <tr key={trip.id}>
                {headers.map((header) => (
                  <td key={header.key} className="whitespace-nowrap cursor-pointer border border-gray-300 px-4 py-2" onClick={() => handleClick(trip, header)}>
                    {header.key === "createdAt"
                      ? trip[header.key]
                        ? new Date(trip[header.key]).toLocaleString()
                        : "N/A"
                      : trip[header.key] || "N/A"}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Trips;
