import React from 'react';

const Loader = ({margin}) => {    
  return (
    <div className="m-10 flex justify-center items-center">
      <div className="relative w-16 h-16 loading"/>
    </div>
  )
}

export default Loader;
