import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs, query, orderBy } from "firebase/firestore";
import Loader from "components/Loader";
import { Link } from "react-router-dom";

const Chats = () => {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // Define table headers
  const headers = [
    { key: "createdAt", label: "Created At" },
    { key: "id", label: "Chat ID" },
    { key: "participants", label: "Participants" },
  ];

  useEffect(() => {
    const fetchChats = async () => {
      const db = getFirestore();

      try {
        setLoading(true);
        setError("");

        // Query chats collection sorted by creation date (createdAt)
        const chatsQuery = query(collection(db, "chats"), orderBy("createdAt", "desc"));
        const chatsSnapshot = await getDocs(chatsQuery);

        // Map the chats data into an array
        const chatsData = chatsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setChats(chatsData);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch chats. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchChats();
  }, []);

  if (loading) {
    return <Loader/>
  }

  if (error) {
    return <div className="p-6 text-center text-red-500">{error}</div>;
  }

  return (
    <div className="max-w-6xl mx-auto shadow-md rounded-lg p-6">
      <h1 className="text-2xl font-bold mb-6">All Chats - {chats.length}</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto border-collapse border border-gray-300">
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header.key} className="border border-gray-300 px-4 py-2">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {chats.map((chat) => (
              <tr key={chat.id}>
                {headers.map((header) => (
                  <td key={header.key} className="border border-gray-300 px-4 py-2">
                  {header.key === "id" ? (
                    <Link to={`/chats/${chat.id}`} className="text-blue-500 underline">
                      {chat[header.key]}
                    </Link>
                  ) : header.key === "createdAt"
                  ? chat[header.key]
                    ? new Date(chat[header.key].seconds * 1000).toLocaleString()
                    : "N/A"
                  : header.key === "participants"
                  ? Object.keys(chat[header.key] || {}).join(", ")
                  : chat[header.key] || "N/A"}
                </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Chats;
