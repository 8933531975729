import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAXieMNGoP0S__G4gqMeeCTd13FQn7QoqU",
  authDomain: "ride-share-d3736.firebaseapp.com",
  projectId: "ride-share-d3736",
  storageBucket: "storage.ride-share.app",
  messagingSenderId: "781551629285",
  appId: "1:781551629285:web:3263e8a25f9996a26ef50c",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get the Functions instance
const functions = getFunctions(app);

// Attach to the Firebase Functions Emulator (update host and port as needed)
// if (process.env.NODE_ENV === "development") {
//   connectFunctionsEmulator(functions, "localhost", 3002);
//   console.log("Connected to functions emulator.");
// }

export default app;