import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { getFirestore, doc, collection, query, orderBy, getDocs, getDoc } from "firebase/firestore";
import Loader from "components/Loader";

const ChatPage = () => {
  const { chatId } = useParams(); // Get the chat ID from the URL
  const [messages, setMessages] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // Darker and less saturated color palette
  const colors = [
    "#4B5563", // Dark Gray
    "#1E293B", // Very Dark Blue Gray
    "#2F3E46", // Deep Teal Gray
    "#3F4551", // Charcoal
    "#4A5568", // Slate Gray
    "#2D3748", // Dark Slate
    "#374151", // Steel Gray
  ];

  const [participantColors, setParticipantColors] = useState({});

  useEffect(() => {
    const fetchChatData = async () => {
      const db = getFirestore();

      try {
        setLoading(true);
        setError("");

        // Fetch chat document to get participants
        const chatDocRef = doc(db, "chats", chatId);
        const chatDoc = await getDoc(chatDocRef);

        if (!chatDoc.exists()) {
          setError("Chat not found.");
          setLoading(false);
          return;
        }

        const participantsData = chatDoc.data().participants || {};
        const participantIds = Object.keys(participantsData);

        setParticipants(participantIds);

        // Assign colors to participants
        const assignedColors = participantIds.reduce((acc, participant, index) => {
          acc[participant] = colors[index % colors.length];
          return acc;
        }, {});
        setParticipantColors(assignedColors);

        // Fetch messages
        const messagesQuery = query(
          collection(db, `chats/${chatId}/messages`),
          orderBy("timestamp", "asc")
        );
        const messagesSnapshot = await getDocs(messagesQuery);

        const messagesData = messagesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMessages(messagesData);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch chat data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchChatData();
  }, [chatId]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div className="p-6 text-center text-red-500">{error}</div>;
  }

  const handleClick = ()=>{
    window.open("https://app.ride-share.app/chats/"+chatId,"_blank")
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
        <h1 className="cursor-pointer text-2xl font-bold mb-4 text-blue-500 hover:underline" onClick={handleClick}>{chatId}</h1>
      <h1 className="text-2xl font-bold mb-4">Chat Participants</h1>
      <ul className="mb-6 space-y-2">
        {participants.map((participant) => (
          <li key={participant}>
            <Link
              to={`/user/${participant}`}
              className="text-blue-500 hover:underline"
            >
              {participant}
            </Link>
          </li>
        ))}
      </ul>

      <h1 className="text-2xl font-bold mb-6">Chat Messages</h1>
      {messages.length === 0 ? (
        <p className="text-gray-500">No messages yet.</p>
      ) : (
        <ul className="space-y-4">
          {messages.map((message) => (
            <li
              key={message.id}
              className="p-4 rounded-lg"
              style={{
                backgroundColor: participantColors[message.senderId],
                color: "#fff",
              }}
            >
              <p className="text-sm text-gray-200">
                {new Date(message.timestamp.seconds * 1000).toLocaleString()}
              </p>
              <p className="text-lg">{message.text}</p>
              <p className="text-xs text-gray-300 mt-1">
                Sender:{" "}
                <Link
                  to={`/user/${message.senderId}`}
                  className="underline text-blue-400"
                >
                  {message.senderId}
                </Link>
              </p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ChatPage;
